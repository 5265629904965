.cover {
  @media (max-width: 768px) {
    width: 50vw;
    height: 50vw;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 20vw;
    height: 20vw;
  }

  @media (min-width: 1024px) and (max-width: 1240px) {
    width: 12vw;
    height: 12vw;
  }

  @media (min-width: 1240px) {
    width: 12vw;
    height: 12vw;
  }
}
