// ArtistMetricsTable.scss

.artist-metrics-table-container {
  background: rgba(0, 0, 0, 0.2);
  max-height: 600px;
  width: 90%;
}

.table_head_cell {
  opacity: 0.6;
  font-family: var(--heading-font);
}

.table_row {
  &:nth-child(even) {
    background: #e3e3e30a;
  }
}

.breakdown_cell {
  color: white;
  background: #000;
  box-shadow: inset -3px 0 8px -6px rgba(255, 255, 255, 0.6);
  font-family: var(--heading-font);
}

.metric_cell {
  opacity: 0.85;
  color: white;
  font-family: var(--heading-font);
}

.table {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .table {
    width: 90vw;
  }
}

.button_group {
  text-align: right;
  margin-top: 20px;

  button {
    text-transform: none;
    font-family: var(--heading-font);
    color: #ababab;

    &.selected {
      color: #ababab;
    }
  }

  span {
    display: flex;
    align-items: center;
    color: #ababab;
    font-family: var(--heading-font);
  }
}
