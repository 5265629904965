.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 10px 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}
