// --- utility classes ---

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}

.g-5 {
  gap: 5px;
}

.g-8 {
  gap: 8px;
}

.g-10 {
  gap: 10px;
}

.g-15 {
  gap: 15px;
}

.g-20 {
  gap: 20px;
}

.g-25 {
  gap: 25px;
}

.g-30 {
  gap: 30px;
}

.g-40 {
  gap: 40px;
}

.m-5 {
  margin: 40px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.h-50 {
  height: 50px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.bg-primary {
  background-color: var(--bg-primary);
}

.bg-secondary {
  background-color: var(--bg-secondary);
}

.bg-tertiary {
  background-color: var(--bg-tertiary);
}

.shadow-overlay:after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background: var(--shadow);
}

.border-10 {
  border-radius: 10px;
  overflow: hidden;
  backface-visibility: hidden;
  isolation: isolate;
  transform: translateZ(0);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cover-image {
  width: 400px;
  height: 400px;
  display: flex;
  position: relative;
  align-self: center;
}

.border-bottom:not(:last-child) {
  border-bottom: 1px solid var(--divider);
}

.border-hover {
  border: 1px solid transparent;
  background-clip: padding-box;
  transition: all var(--transition);
  position: relative;
  border-radius: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(
      180deg,
      #7a7979 0%,
      rgba(220, 220, 220, 0.255) 100%
    );
  }

  &--horizontal:before {
    background: linear-gradient(90deg, #424242 0%, rgba(66, 66, 66, 0) 100%);
  }

  &:hover {
    border-color: var(--accent);
  }
}

.square {
  aspect-ratio: 1/1;
}

.link-hover {
  transition: color var(--transition);

  &:hover,
  &:focus {
    color: var(--accent);
  }

  &--invert {
    &:hover,
    &:focus {
      color: var(--text-light);
    }
  }
}

.disabled {
  pointer-events: none !important;
}

.meta {
  font-size: var(--text-xs);
  color: var(--meta);
  font-family: var(--heading-font);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--heading-font);
}

.text-uppercase {
  text-transform: uppercase;
  font-family: var(--heading-font);
}

.text-bold {
  font-weight: 500;
  font-family: var(--heading-font);
}

.text-divider {
  color: var(--divider);
  font-family: var(--heading-font);
}

.text-sm {
  font-size: var(--text-sm);
  line-height: 1.3;
  font-family: var(--heading-font);
}

.text-small {
  font-size: var(--text-sm);
  font-family: var(--heading-font);
}

.text-xs {
  font-size: var(--text-xs);
  line-height: 1.4;
  font-family: var(--heading-font);
}

.text-light {
  color: var(--text-light);
  font-family: var(--heading-font);
}

.text-accent {
  color: var(--accent);
  font-family: var(--heading-font);
}

.text-body {
  color: var(--text);
  font-family: var(--heading-font);
}

.text-gradient {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
