.details {
  margin: 30px 0;

  @media screen and (min-width: 768px) {
    margin: 60px 0;
  }

  &_container {
    display: grid;
    gap: 0px;
  }
}

.fadeIn {
  animation: fadeInAnimation 0.5s ease-in-out;
  -webkit-animation: fadeInAnimation 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
