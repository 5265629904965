.input {
  &_title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
}

.cover {
  @media (max-width: 768px) {
    width: 40%;
    height: 40%;
    margin: 1rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%;
    height: 40%;
    margin: 1rem;
  }

  @media (min-width: 1024px) and (max-width: 1240px) {
    width: 40%;
    height: 40%;
    margin: 1rem;
  }

  @media (min-width: 1240px) {
    width: 40%;
    height: 40%;
    margin: 1rem;
  }
}
