@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon/icomoon.woff') format('woff'),
    url('../../fonts/icomoon/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-logout-solid:before {
    content: "\e934";
}

.icon-logout-regular:before {
    content: "\e935";
}

.icon-search-regular:before {
    content: "\e932";
}

.icon-wallet-regular:before {
    content: "\e933";
}

.icon-sliders-solid:before {
    content: "\e92f";
}

.icon-sliders-regular:before {
    content: "\e930";
}

.icon-bars-sort:before {
    content: "\e931";
}

.icon-play-solid:before {
    content: "\e928";
}

.icon-bars-regular:before {
    content: "\e929";
}

.icon-bars-solid:before {
    content: "\e92a";
}

.icon-user-minus-regular:before {
    content: "\e92b";
}

.icon-user-minus-solid:before {
    content: "\e92c";
}

.icon-user-plus-solid:before {
    content: "\e92d";
}

.icon-user-plus-regular:before {
    content: "\e92e";
}

.icon-circle-check:before {
    content: "\e927";
}

.icon-tags-regular:before {
    content: "\e924";
}

.icon-heart-o-regular:before {
    content: "\e925";
}

.icon-basket-regular:before {
    content: "\e926";
}

.icon-check-regular:before {
    content: "\e922";
}

.icon-check-solid:before {
    content: "\e923";
}

.icon-xmark:before {
    content: "\e900";
}

.icon-calendar:before {
    content: "\e901";
}

.icon-comment:before {
    content: "\e902";
}

.icon-reply:before {
    content: "\e903";
}

.icon-bookmark:before {
    content: "\e904";
}

.icon-basket:before {
    content: "\e905";
}

.icon-bid:before {
    content: "\e906";
}

.icon-tags:before {
    content: "\e909";
}

.icon-share:before {
    content: "\e90a";
}

.icon-copy:before {
    content: "\e90b";
}

.icon-check:before {
    content: "\e90c";
}

.icon-ethereum:before {
    content: "\e90d";
}

.icon-rocket:before {
    content: "\e90e";
}

.icon-trophy:before {
    content: "\e90f";
}

.icon-headset:before {
    content: "\e910";
}

.icon-shield:before {
    content: "\e911";
}

.icon-heart:before {
    content: "\e912";
}

.icon-heart-o:before {
    content: "\e913";
}

.icon-linkedin:before {
    content: "\e914";
}

.icon-discord:before {
    content: "\e915";
}

.icon-telegram:before {
    content: "\e916";
}

.icon-youtube:before {
    content: "\e917";
}

.icon-instagram:before {
    content: "\e918";
}

.icon-facebook:before {
    content: "\e919";
}

.icon-twitter:before {
    content: "\e91a";
}

.icon-arrow-right:before {
    content: "\e91b";
}

.icon-user:before {
    content: "\e91c";
}

.icon-angle-up:before {
    content: "\e91d";
}

.icon-angle-down:before {
    content: "\e91e";
}

.icon-angle-left:before {
    content: "\e91f";
}

.icon-angle-right:before {
    content: "\e920";
}

.icon-search:before {
    content: "\e921";
}

.icon-dot:before {
    content: "\e907";
}

.icon-ellipsis:before {
    content: "\e908";
}
