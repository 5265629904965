.main {
  max-width: 660px;

  &_text {
    margin: 20px 0 40px;
    font-family: var(--heading-font);
    font-weight: 500;
  }

  &_buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (min-width: 414px) {
      flex-direction: row;
    }
  }
}

.loading {
  flex-direction: column;
}
