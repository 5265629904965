.grid {
    display: grid;
    gap: 30px 20px;
    
    @media screen and (min-width: 568px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (min-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}