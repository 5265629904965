.input {
  &_title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.platforms {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .platforms {
    height: 4rem;
    width: 22.6rem;
  }
}
