.details {
  margin: 30px 0;

  @media screen and (min-width: 768px) {
    margin: 60px 0;
  }

  &_container {
    display: grid;
    gap: 0px;
  }
}

.main {
  &_text {
    margin: 25px 0 30px;
  }

  &_amount_raised {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  &_percentages {
    font-family: var(--heading-font);
    font-weight: 600;
  }

  &_about {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 26px;
    }

    .bid {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 30px;
    }

    .actions {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      .btn {
        color: var(--text);
      }

      .like {
        font-weight: 500;
        min-width: 80px;
        font-size: var(--text-sm);

        .wrapper i {
          font-size: var(--text-xs);
        }
      }
    }
  }

  &_creator {
    margin-bottom: 30px;
    display: grid;
    gap: 20px;

    .block {
      padding: 20px;
      border: 1px solid var(--divider);
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: var(--bg-secondary);

      &_details {
        max-width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }

    .dark_block {
      padding: 20px;
      border: 2px solid rgba(22, 22, 22, 0.802);
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: #0f0f0f64;

      &_details {
        max-width: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}

.buttons {
  display: grid;
  gap: 20px;
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 2px solid var(--divider);
}

.header {
  margin: 20px 0;
  text-align: left;

  &_title {
    line-height: 1;
    text-align: left;
  }

  &_subtitle {
    opacity: 0.6;
    text-align: left;
  }

  @media screen and (min-width: 768px) {
    margin: 35px 0;
    text-align: left;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--divider);
  border-bottom: none;

  tr {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  td {
    padding: 15px;
    border-bottom: 1px solid var(--divider);
  }

  @media screen and (min-width: 568px) {
    tr {
      flex-direction: row;

      td {
        &:first-child {
          width: 40%;
          border-right: 1px solid var(--divider);
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .details_container {
    grid-template-columns: 300px minmax(0, calc(100% - 300px));
    gap: 40px;
    align-items: flex-start;
  }

  .main_about .title {
    font-size: 38px;
  }

  .buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .details_container,
  .main_creator {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1170px) {
  .details_container {
    gap: 70px;
  }
}

@media screen and (min-width: 1280px) {
  .main_about {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .actions {
      margin-top: 0;
    }
  }
}

.usdc_logo {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0.5rem;
}
