.container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  /* Add hover effect */
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
