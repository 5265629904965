.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*Centerhorizontally*/
  justify-content: center;
  /*Centervertically*/
  background-color: rgba(20, 20, 20, 0.825);
  border-radius: 10px;
  padding: 16px;
  outline: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
