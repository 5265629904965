.container {
    height: var(--elements-height);
    padding: 0 15px 0 10px;
    border-radius: 50px;
    width: fit-content;

    a {
        transition: color var(--transition);

        &:hover {
            color: var(--text-light);
        }
    }
}

.avatar {
    border-radius: 50%;
    width: 30px;
    height: 30px !important;
}