.wrapper {
  padding: 10px 20px 20px;
  min-height: 580px;
  max-height: 700px;
}

.media {
  margin: 10px 0 20px;
  position: relative;
}

.main {
  &_countdown {
    margin: 7px 0 10px;
    display: flex;
    justify-content: space-between;
  }

  &_btn {
    font-family: var(--heading-font);
    font-weight: 600;
  }

  &_percentages {
    font-family: var(--heading-font);
    font-weight: 600;
  }

  &_amount_raised {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    justify-items: center;
  }
}

.usdc_logo {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0.5rem;
}
